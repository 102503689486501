import './list.scss';
import dataApi from '@/api/system/couponissuerule.js';
import activity from '@/api/manage/activity';
// import check from '@/api/workbench.js';

export default {
  name: 'account-list',
  components: {},
  data() {
    return {
      title: '',
      dic: [],
      formDa: {},
      page: {
        pageSize: 10,
        pageSizes: [10, 20, 30, 40, 50, 100]
      },
      searchData: {},
      tableData: [],
      dialogVisible: false,
      account: {},
      tbleType: true
    };
  },
  computed: {
    tbleOption() {
      return {
        align: 'center',
        addBtn: false,
        editBtn: false,
        searchMenuPosition: 'left',
        filterBtn: false,
        delBtn: false,
        ...this.$store.getters.tableConfig,
        searchMenuSpan: 9,
        column: [
          {
            label: '名称',
            type: 'input',
            span: 24,
            prop: 'name',
            rules: [
              {
                required: true,
                message: '请输入名称'
              }
            ]
          },
          {
            label: '场景值',
            type: 'select',
            span: 24,
            prop: 'scenario',
            search: 'true',
            dicUrl: `${this.$baseUrl}general/get_dict`,
            dicMethod: 'post',
            dicFlag: true,
            filterable: false,
            multiple: false,
            dicQuery: {
              dict_type: 'coupon_issue'
            },
            required: true,
            rules: [
              {
                required: true,
                message: '请选择使用场景'
              }
            ]
          },
          {
            label: '所属活动',
            type: 'select',
            span: 24,
            hide: true,
            display: this.formDa.scenario === 'exchange',
            prop: 'product',
            formslot: true,
            rules: [
              {
                required: true,
                message: '请选择所属活动'
              }
            ]
          },
          {
            label: '最小值',
            type: 'number',
            span: 12,
            hide: true,
            display: this.formDa.scenario === 'top_up',
            prop: 'min',
            formslot: true,
            rules: [
              {
                required: true,
                message: '请选择所属活动'
              }
            ]
          },
          {
            label: '最大值',
            type: 'number',
            span: 12,
            hide: true,
            display: this.formDa.scenario === 'top_up',
            prop: 'max',
            formslot: true,
            rules: [
              {
                required: true,
                message: '请选择所属活动'
              }
            ]
          },
          {
            type: 'select',
            label: '会员等级',
            span: 24,
            hide: true,
            display: this.formDa.scenario === 'upgrade',
            prop: 'member_level',
            dicFlag: true,
            filterable: true,
            dicUrl: `${this.$baseUrl}general/get_member_rank`,
            dicMethod: 'get',
            rules: [
              {
                required: true,
                message: '升级到'
              }
            ]
          },
          {
            label: '状态',
            span: 12,
            rules: [
              {
                required: true,
                message: '请选择用户状态',
                trigger: 'blur'
              }
            ],
            type: 'radio',
            value: 1,
            dicData: [
              {
                label: '正常',
                value: 1
              },
              {
                label: '停用',
                value: 0
              }
            ],
            prop: 'status'
          },
          {
            label: '创建时间',
            span: 12,
            display: false,
            prop: 'create_time'
          },
          {
            label: '说明',
            hide: true,
            prop: 'remark',
            type: 'textarea',
            span: 24
          }
        ]
      };
    }
  },
  created() {

  },
  watch: {
  },
  filters: {},
  methods: {
    // 分页
    onLoad(page) {
      this.page = page;
      this.getList();
    },
    // 筛选
    searchChange(params, done) {
      this.searchData = params;
      if (this.searchData.date_time) {
        this.searchData = {
          ...params,
          startdate: params.date_time[0],
          enddate: params.date_time[1]
          // date_type: 1
        };
      }
      this.page.currentPage = 1;
      this.getList();
      done();
    },
    // 筛选清空
    searchReset() {
      this.page.currentPage = 1;
      this.searchData = {};
      this.getList();
    },
    // 添加
    async handleSubmit(form, done) {
      form.rule = {};
      form.rule.product = form.product ? form.product : '';
      form.rule.max = form.max ? form.max : '';
      form.rule.min = form.min ? form.min : '';
      form.rule.member_level = form.member_level ? form.member_level : '';
      if (this.title === '新增') {
        dataApi.add.r(form).then(() => {
          done(form);
          this.getList();
          this.dialogVisible = false;
        });
      } else {
        dataApi.update.r(form).then(() => {
          done(form);
          this.dialogVisible = false;
          loading();
        });
      }
    },
    emptytChange() {
      this.dialogVisible = false;
    },
    add() {
      activity.getList.r().then(res => {
        console.log(res);
        this.dic = res.data.list;
      });
      this.title = '新增';
      this.dialogVisible = true;
    },
    // 删除
    rowDel(form) {
      this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        console.log('删除数据', form);
        dataApi.del.r({ id: form.id }).then((res) => {
          console.log(res);
          this.getList();
          this.$message.success('删除成功');
        });
      });
    },
    // 编辑
    rowEdit(form) {
      dataApi.getOne.r({ id: form.id }).then(res => {
        this.formDa = res.data;
        this.formDa.product = res.data.rule.product;
        this.formDa.min = res.data.rule.min;
        this.formDa.max = res.data.rule.max;
        this.formDa.member_level = res.data.rule.member_level;
        this.dialogVisible = true;
        this.title = '修改';
      });
      activity.getList.r().then(res => {
        this.dic = res.data.list;
      });
    },
    // 获取
    getList() {
      const postData = {
        page: this.page.currentPage,
        pageSize: this.page.pageSize,
        ...this.searchData
      };
      console.log('接口参数', postData);
      dataApi.getList.r(postData).then((res) => {
        console.log('接口返回', res);
        this.tableData = res.data.list;
        this.page.total = res.data.total;
      });
    }
  }
};
