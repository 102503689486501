import http from '@/utils/http';

/**
 * 活动管理
 */
export default {
  getList: {
    p: 'post,/manage/activity/getlist',
    r: (data) => http({ url: '/manage/activity/getlist', method: 'post', data })
  },
  add: {
    p: 'post,/manage/activity/add',
    r: (data) => http({ url: '/manage/activity/add', method: 'post', data })
  },
  update: {
    p: 'post,/manage/activity/update',
    r: (data) => http({ url: '/manage/activity/update', method: 'post', data })
  },
  del: {
    p: 'post,/manage/activity/delete',
    r: (data) => http({ url: '/manage/activity/delete', method: 'post', data })
  },
  getOne: {
    p: 'post,/manage/activity/getone',
    r: (data) => http({ url: '/manage/activity/getone', method: 'post', data })
  },
  copy: {
    p: 'post,/manage/activity/copy',
    r: (data) => http({ url: '/manage/activity/copy', method: 'post', data })
  },
  getPlanList: {
    p: 'post,/manage/activityplan/getlist',
    r: (data) => http({ url: '/manage/activityplan/getlist', method: 'post', data })
  },
  addPlan: {
    p: 'post,/manage/activityplan/add',
    r: (data) => http({ url: '/manage/activityplan/add', method: 'post', data })
  },
  updatePlan: {
    p: 'post,/manage/activityplan/update',
    r: (data) => http({ url: '/manage/activityplan/update', method: 'post', data })
  },
  delPlan: {
    p: 'post,/manage/activityplan/delete',
    r: (data) => http({ url: '/manage/activityplan/delete', method: 'post', data })
  },
  getPlanOne: {
    p: 'post,/manage/activityplan/getone',
    r: (data) => http({ url: '/manage/activityplan/getone', method: 'post', data })
  },
  copyPlan: {
    p: 'post,/manage/activityplan/copy',
    r: (data) => http({ url: '/manage/activityplan/copy', method: 'post', data })
  },
  copyActivity: {
    p: 'post,/manage/activity/copy',
    r: (data) => http({ url: '/manage/activity/copy', method: 'post', data })
  },
  addActivityPlan: {
    p: 'post,/manage/activityplan/batch_add',
    r: (data) => http({ url: '/manage/activityplan/batch_add', method: 'post', data })
  }
};
