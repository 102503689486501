import './list.scss';
import dataApi from '@/api/manage/coupon';
// import activity from '@/api/product/activity';
// import role from '@/api/system/role.js';
// import check from '@/api/workbench.js';
import workbench from '@/api/manage/workbench';

export default {
  name: 'account-list',
  components: {},
  data() {
    return {
      dic: [],
      title: '',
      page: {
        pageSize: 10,
        pageSizes: [10, 20, 30, 40, 50, 100]
      },
      formDa: {},
      issueDa: {},
      searchData: {},
      tableData: [],
      detailData: [],
      dialogVisible: false,
      issueVisible: false,
      viewVisible: false,
      account: {},
      tbleType: true
    };
  },
  computed: {
    tableOption() {
      return {
        menuWidth: 300,
        align: 'center',
        searchMenuPosition: 'left',
        filterBtn: false,
        delBtn: false,
        editBtn: false,
        addBtn: false,
        ...this.$store.getters.tableConfig,
        column: [
          {
            type: 'input',
            label: '优惠券名称',
            span: 24,
            rules: [
              {
                required: true,
                message: '请输入优惠券名称',
                trigger: 'blur'
              }
            ],
            prop: 'name'
          },
          {
            label: '优惠券类型',
            type: 'select',
            span: 12,
            prop: 'type',
            value: 1,
            control: (val) => {
              if (val === 1) {
                return {
                  product: {
                    display: false
                  },
                  use_type: {
                    display: true
                  }
                };
              }
              if (val === 2) {
                return {
                  product: {
                    display: true
                  },
                  use_type: {
                    display: false
                  }
                };
              }
              return {
                product: {
                  display: false
                },
                use_type: {
                  display: false
                }
              };
            },
            dicData: [
              {
                label: '兑换券',
                value: 1
              },
              {
                label: '优惠券',
                value: 2
              },
              {
                label: '抵扣券',
                value: 3
              }
            ],
            required: true,
            rules: [
              {
                required: true,
                message: '请选择优惠券类型'
              }
            ]
          },
          {
            label: '领取场景',
            type: 'select',
            span: 12,
            prop: 'scenario',
            dicUrl: `${this.$baseUrl}general/get_dict`,
            dicMethod: 'post',
            dicFlag: true,
            filterable: false,
            multiple: false,
            dicQuery: {
              dict_type: 'coupon'
            },
            required: true,
            rules: [
              {
                required: true,
                message: '请选择领取场景'
              }
            ]
          },
          {
            label: '使用门槛',
            span: 24,
            type: 'input',
            prop: 'min_point'
          },
          {
            type: 'number',
            label: '数量',
            span: 12,
            value: 0,
            hide: true,
            rules: [
              {
                required: true,
                message: '请输入优惠券名称',
                trigger: 'blur'
              }
            ],
            prop: 'count'
          },
          {
            type: 'number',
            label: '金额/能量值',
            span: 12,
            value: 0,
            rules: [
              {
                required: true,
                message: '请输入金额/能量值',
                trigger: 'blur'
              }
            ],
            prop: 'amount'
          },
          {
            type: 'number',
            label: '发行数量',
            span: 12,
            value: 0,
            hide: true,
            rules: [
              {
                required: true,
                message: '请输入发行数量',
                trigger: 'blur'
              }
            ],
            prop: 'publish_count'
          },
          {
            type: 'number',
            label: '限领张数',
            span: 12,
            value: 0,
            hide: true,
            rules: [
              {
                required: true,
                message: '请输入限领张数',
                trigger: 'blur'
              }
            ],
            prop: 'per_limit'
          },
          {
            type: 'datetime',
            label: '开始时间',
            format: 'yyyy-MM-dd hh:mm',
            valueFormat: 'yyyy-MM-dd hh:mm',
            span: 12,
            pickerOptions: {
              disabledDate(time) {
                return time.getTime() < Date.now();
              }
            },
            rules: [
              {
                required: true,
                message: '请输入开始时间',
                trigger: 'blur'
              }
            ],
            prop: 'start_time'
          },
          {
            type: 'datetime',
            label: '结束时间',
            format: 'yyyy-MM-dd hh:mm',
            valueFormat: 'yyyy-MM-dd hh:mm',
            span: 12,
            pickerOptions: {
              disabledDate(time) {
                return time.getTime() < Date.now();
              }
            },
            rules: [
              {
                required: true,
                message: '请输入结束时间',
                trigger: 'blur'
              }
            ],
            prop: 'end_time'
          },
          {
            type: 'date',
            label: '领取的日期',
            span: 24,
            format: 'yyyy-MM-dd',
            valueFormat: 'yyyy-MM-dd',
            hide: true,
            pickerOptions: {
              disabledDate(time) {
                return time.getTime() < Date.now();
              }
            },
            rules: [
              {
                required: true,
                message: '请输入可以领取的日期',
                trigger: 'blur'
              }
            ],
            prop: 'enable_time'
          },
          {
            type: 'input',
            label: '优惠码',
            span: 24,
            hide: true,
            prop: 'code'
          },
          {
            type: 'select',
            label: '会员等级',
            span: 24,
            display: true,
            prop: 'member_level',
            dicFlag: true,
            filterable: true,
            dicUrl: `${this.$baseUrl}general/get_member_rank`,
            dicMethod: 'get'
          },
          {
            label: '使用类型',
            type: 'radio',
            span: 12,
            hide: true,
            prop: 'use_type',
            search: 'true',
            value: 1,
            control: (val) => {
              if (val === 1) {
                return {
                  product: {
                    display: false
                  }
                };
              }
              if (val === 2) {
                return {
                  product: {
                    display: true
                  }
                };
              }
            },
            dicData: [
              {
                label: '全场通用',
                value: 1
              },
              {
                label: '指定活动',
                value: 2
              }
            ],
            required: true,
            rules: [
              {
                required: true,
                message: '请选择优惠券类型'
              }
            ]
          },
          {
            label: '状态',
            span: 12,
            rules: [
              {
                required: true,
                message: '请选择用户状态',
                trigger: 'blur'
              }
            ],
            type: 'radio',
            value: 1,
            search: true,
            dicData: [
              {
                label: '正常',
                value: 1
              },
              {
                label: '停用',
                value: 0
              }
            ],
            prop: 'status'
          },
          {
            label: '所属活动',
            prop: 'product',
            type: 'select',
            search: true,
            span: 24,
            hide: true,
            props: {
              label: 'label',
              value: 'value'
            },
            dicUrl: `${this.$baseUrl}general/get_product`,
            dicQuery: {},
            dicMethod: 'post',
            display: false,
            showColumn: false
          }
        ]
      };
    },
    detailOption() {
      return {
        align: 'center',
        index: false,
        searchMenuPosition: 'left',
        menu: false,
        filterBtn: false,
        delBtn: false,
        editBtn: false,
        addBtn: false,
        ...this.$store.getters.tableConfig,
        searchMenuSpan: 9,
        column: [
          {
            type: 'input',
            label: '优惠码',
            span: 24,
            prop: 'coupon_code'
          },
          {
            type: 'input',
            label: '领取会员',
            span: 24,
            prop: 'member_nickname'
          },
          {
            type: 'select',
            label: '指定会员',
            span: 24,
            search: 'true',
            hide: true,
            props: {
              label: 'label',
              value: 'value'
            },
            dicUrl: `${this.$baseUrl}general/get_member?keyword={{key}}`,
            remote: true,
            dicMethod: 'post',
            prop: 'member_id'
          },
          {
            label: '领取方式',
            type: 'select',
            span: 12,
            prop: 'get_type_text',
            value: 1
          },
          {
            label: '领取时间',
            type: 'select',
            span: 12,
            prop: 'create_time'
          },
          {
            label: '状态',
            type: 'select',
            search: 'true',
            prop: 'status_text',
            dicData: [
              {
                label: '未使用',
                value: 1
              },
              {
                label: '已过期',
                value: 3
              },
              {
                label: '使用',
                value: 2
              }
            ]
          },
          {
            label: '订单号',
            prop: 'order_sn'
          },
          {
            label: '使用时间',
            prop: 'use_time'
          }
        ]
      };
    },
    issueOption() {
      return {
        ...this.$store.getters.tableConfig,
        searchMenuSpan: 9,
        column: [
          {
            type: 'select',
            label: '指定会员',
            span: 24,
            multiple: true,
            props: {
              label: 'label',
              value: 'value'
            },
            dicUrl: `${this.$baseUrl}general/get_member?keyword={{key}}`,
            remote: true,
            dicMethod: 'post',
            prop: 'member_ids'
          },
          {
            type: 'select',
            label: '会员等级',
            multiple: true,
            span: 24,
            display: true,
            prop: 'member_level',
            dicFlag: true,
            filterable: true,
            dicUrl: `${this.$baseUrl}general/get_member_rank`,
            dicMethod: 'post'
          },
          {
            label: '全部会员',
            type: 'select',
            span: 24,
            prop: 'member_all',
            dicData: [
              {
                label: '是',
                value: 1
              },
              {
                label: '否',
                value: 0
              }
            ]
          }
        ]
      };
    }
  },
  created() {
    workbench.getProduct.r().then((res) => {
      this.dic = res.data;
    });
  },
  watch: {},
  filters: {},
  methods: {
    issue(form) {
      this.issueDa.coupon_id = form.id;
      this.issueDa.type = form.type;
      this.issueVisible = true;
    },
    // 分页
    onLoad(page) {
      this.page = page;
      this.getList();
    },
    detailGetList() {
      const postData = {
        page: this.page.currentPage,
        pageSize: this.page.pageSize,
        ...this.searchData
      };
      dataApi.couponhistory.r(postData).then((res) => {
        console.log(res.data.list);
        this.detailData = res.data.list;
      });
    },
    // 筛选
    searchChange(params, done) {
      this.searchData = params;
      if (this.searchData.date_time) {
        this.searchData = {
          ...params,
          startdate: params.date_time[0],
          enddate: params.date_time[1]
          // date_type: 1
        };
      }
      this.page.currentPage = 1;
      this.getList();
      this.detailGetList();
      done();
    },
    rowIssue(form, done) {
      dataApi.issue.r(form).then(() => {
        this.issueVisible = false;
        this.issueDa = {};
        done(form);
      });
    },
    issueChange() {
      this.issueVisible = false;
    },
    // 筛选清空
    searchReset() {
      this.page.currentPage = 1;
      this.searchData = {};
      this.getList();
    },
    addCoupon() {
      this.dialogVisible = true;
      this.title = '新增';
    },
    rowEdit(form) {
      dataApi.getone.r({ id: form.id }).then((res) => {
        this.formDa = res.data;
        this.dialogVisible = true;
        this.title = '修改';
      });
    },
    rowView(form) {
      dataApi.getone.r({ id: form.id }).then((res) => {
        this.formDa = res.data;
      });
      this.viewVisible = true;
    },
    // 删除
    rowDel(form) {
      this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        console.log('删除数据', form);
        dataApi.del.r({ id: form.id }).then((res) => {
          console.log(res);
          this.getList();
          this.$message.success('删除成功');
        });
      });
    },
    query() {
      dataApi.general.r({ keyword: this.search }).then((res) => {
        this.formDa.product = res.result;
      });
    },
    emptytChange() {
      this.dialogVisible = false;
    },
    // 表单提交
    rowUpdate(form, done) {
      if (this.title === '新增') {
        dataApi.add.r(form).then((res) => {
          console.log(res);
          this.dialogVisible = false;
          this.getList();
        });
      } else {
        dataApi.update.r(form).then((res) => {
          console.log(res);
          this.dialogVisible = false;
          this.getList();
        });
      }

      done();
    },
    // 获取
    getList() {
      const postData = {
        page: this.page.currentPage,
        pageSize: this.page.pageSize,
        ...this.searchData
      };
      dataApi.getList.r(postData).then((res) => {
        console.log('接口返回', res);
        this.tableData = res.data.list;
        this.page.total = res.data.total;
      });
    }
  }
};
