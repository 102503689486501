<template>
  <div :class="{ 'page-class': !$store.getters.tableConfig.card }">
    <avue-crud
        ref="crud"
        :data="tableData"
        :option="tbleOption"
        @refresh-change="getList"
        @row-del="rowDel"
        @on-load="onLoad"
        @search-change="searchChange"
        @search-reset="searchReset"
        :page.sync="page"
    >
      <template slot="sku" slot-scope="{ type, size, row, index }">
        <el-button
            icon="el-icon-edit-outline"
            type="primary"
            size="small"
            round
            @click.stop="editSku(row, index)"
        ></el-button>
      </template>
      <template slot-scope="{ type, size, row, index }" slot="menu">
        <el-button
            icon="el-icon-s-grid"
            :size="size"
            :type="type"
            v-if="row.is_course === 3"
            @click.stop="plan(row, index)"
        >活动安排</el-button
        >

        <el-button
            icon="el-icon-edit-outline"
            :size="size"
            :type="type"
            @click.stop="clickLink(row, index)"
        >编辑</el-button
        >
        <el-button
            icon="el-icon-delete"
            :size="size"
            :type="type"
            @click.stop="rowDel(row, index)"
        >删除</el-button
        >
        <!-- <el-button
          icon="el-icon-view"
          :size="size"
          :type="type"
          @click.stop="rowView(row, index)"
          >查看</el-button
        > -->
      </template>
    </avue-crud>
    <el-drawer
        title="排课详情"
        v-model="drawerCourseVisible"
        size="70%"
        :modal="false"
        :close-on-click-modal="false"
        :custom-class="'drawer-form'"
        :visible.sync="drawerCourseVisible"
        :destroy-on-close="true"
    >
      <avue-crud
          ref="crud"
          :data="tablePromDa"
          :option="tablePromOp"
          @refresh-change="getPlanList"
          @row-del="rowPlanDel"
          @on-load="onPlanLoad"
          :page.sync="planPage"
      >
        <template slot="menuLeft">
          <el-button
              icon="el-icon-plus"
              type="primary"
              size="small"
              @click.stop="editArranging({ id: '0' })"
          >创建班级</el-button
          >
        </template>
        <template slot="apply_quantity" slot-scope="{ row }">
          <div @click="getSignUpList(row)" class="signup">{{ row.apply_quantity  }}</div> 
        </template>
        <template slot-scope="{ type, size, row, index }" slot="menu">
          <el-button
              icon="el-icon-edit-outline"
              :size="size"
              :type="type"
              @click.stop="editArranging(row, index)"
          >编辑</el-button
          >
          <el-button
              icon="el-icon-delete"
              :size="size"
              :type="type"
              @click.stop="rowPlanDel(row, index)"
          >删除</el-button
          >
        </template>
      </avue-crud>
    </el-drawer>
    <!--编辑sku-->
    <el-dialog
        :title="dialogTitle"
        top="5vh"
        size="900"
        :close-on-click-modal="false"
        :visible.sync="skuDialogVisible"
        @close="dialogCloseCallback"
    >
      <avue-form
          ref="skuForm"
          v-model="formDa"
          :option="formOp"
          @submit="dialogSubmit"
          :key="reload"
      >
        <template slot-scope="{ disabled, size }" slot="button">
          <el-button
              type="primary"
              :size="size"
              :disabled="disabled"
              @click="handleRefreshCourse"
          >
            开始活动安排
          </el-button>
        </template>
        <template slot-scope="{}" slot="skuStockList">
          <el-table style="width: 100%" :data="formDa.skuStockList" border>
            <el-table-column label="SKU编号" width="80" align="center">
              <template slot-scope="scope">
                {{ scope.row.sku_code }}
              </template>
            </el-table-column>
            <el-table-column
                v-for="(item, index) in productAttrValue"
                :label="item.name"
                :key="item.id"
                align="center"
                min-width="80"
                max-width="140"
            >
              <template slot-scope="scope">
                {{ getProductSkuSp(scope.row, index) }}
              </template>
            </el-table-column>
            <el-table-column label="销售价格" width="80" align="center">
              <template slot-scope="scope">
                <el-input v-model="scope.row.price"></el-input>
              </template>
            </el-table-column>
            <el-table-column label="活动库存" width="80" align="center">
                  <template slot-scope="scope">
                    <el-input v-model="scope.row.stock"></el-input>
              </template>
            </el-table-column>
            <el-table-column label="库存预警值" width="80" align="center">
              <template slot-scope="scope">
                <el-input v-model="scope.row.low_stock"></el-input>
              </template>
            </el-table-column>

            <el-table-column
                v-if="formDa.is_course > 0"
                label="课时"
                width="160"
                align="center"
            >
              <template slot-scope="scope">
                <el-input-number
                    v-model="scope.row.class"
                    :min="1"
                ></el-input-number>
              </template>
            </el-table-column>
          </el-table>
        </template>
      </avue-form>
    </el-dialog>
    <!--报名会员名单-->
    <el-dialog
        title="报名会员"
        top="5vh"
        size="800"
        :close-on-click-modal="false"
        :visible.sync="signDialogVisible"
        @close="dialogClose"
    >
      <avue-crud
      :data="tableSignDa"
      :option="tableSignOp"
      @refresh-change="getSignUpList"
      :page.sync="signPage"
    >
    </avue-crud>
    </el-dialog>
  </div>
</template>
<script>
import List from './list';

export default List;
</script>
