import http from '@/utils/http';

/**
 * 工作台相关
 */
export default {
  // 查询会员
  getMember: {
    p: 'post',
    r: (data) => http({ url: '/general/get_member', method: 'post', data })
  },
  // 查询会员余额
  getMoeny: {
    p: 'post',
    r: (data) => http({ url: '/work/calculate/moeny', method: 'post', data })
  },
  // 充值
  getDeposit: {
    p: 'post',
    r: (data) => http({ url: '/work/energy/topup', method: 'post', data })
  },
  // 获取活动时间
  getActivityPlan: {
    p: 'post,  /manage/exchange/plan',
    r: (data) => http({ url: '/manage/exchange/plan', method: 'post', data })
  },
  // 添加活动兑换
  addExchange: {
    p: 'post,/manage/exchange/add',
    r: (data) => http({ url: '/manage/exchange/add', method: 'post', data })
  },
  queryActivity: {
    p: 'post,/work/member/activity',
    r: (data) => http({ url: '/work/member/activity', method: 'post', data })
  },
  getNuclear: {
    p: 'post,/work/activity/nuclear',
    r: (data) => http({ url: '/work/activity/nuclear', method: 'post', data })
  },
  // 获取充值模板
  energyTpl: {
    p: 'post,/general/energy_tpl',
    r: (data) => http({ url: '/general/energy_tpl', method: 'post', data })
  },
  // 新增会员
  addMember: {
    p: 'post,/manage/member/add',
    r: (data) => http({ url: '/manage/member/add', method: 'post', data })
  },
  // 会员退款
  refund: {
    p: 'post,/work/member/refund',
    r: (data) => http({ url: '/work/member/refund', method: 'post', data })
  },
  // 获取产品
  getProduct: {
    p: 'post,/general/get_product',
    r: (data) => http({ url: '/general/get_product', method: 'post', data })
  },
  // 获取课程
  getCourse: {
    p: 'post,/general/get_teacher_course',
    r: (data) =>
      http({ url: '/general/get_teacher_course', method: 'post', data })
  },
  // 根据活动、教师获取会员
  getTeacherMember: {
    p: 'post,/general/get_teacher_member',
    r: (data) =>
      http({ url: '/general/get_teacher_member', method: 'post', data })
  },
  // 提交签到
  submitSignIn: {
    p: 'post,/work/member/signin',
    r: (data) => http({ url: '/work/member/signin', method: 'post', data })
  },
  // 常规活动批量签到
  submitBatchSignIn: {
    p: 'post,/work/batch/sign',
    r: (data) => http({ url: '/work/batch/sign', method: 'post', data })
  },
  // 常规活动批量兑换
  submitExchangeBatch: {
    p: 'post,/work/batch/exchange',
    r: (data) => http({ url: '/work/batch/exchange', method: 'post', data })
  },
  // 查询指定活动和会员是否可以签到
  queryMemberProductSign: {
    p: 'post,/work/query/signin',
    r: (data) => http({ url: '/work/query/signin', method: 'post', data })
  }
};
