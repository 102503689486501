import http from '@/utils/http';

/**
 * 教师管理
 */
export default {
  getList: {
    p: 'post,/manage/teacher/getlist',
    r: (data) => http({ url: '/manage/teacher/getlist', method: 'post', data })
  },
  add: {
    p: 'post,/manage/teacher/add',
    r: (data) => http({ url: '/manage/teacher/add', method: 'post', data })
  },
  update: {
    p: 'post,/manage/teacher/update',
    r: (data) => http({ url: '/manage/teacher/update', method: 'post', data })
  },
  del: {
    p: 'post,/manage/teacher/delete',
    r: (data) => http({ url: '/manage/teacher/delete', method: 'post', data })
  },
  getOne: {
    p: 'post,/manage/teacher/getOne',
    r: (data) => http({ url: '/manage/teacher/getone', method: 'post', data })
  }
};
