import http from '@/utils/http';

/**
 * 单页管理
 */
export default {
  getList: {
    p: 'get,/manage/couponissuerule/getlist',
    r: () => http({ url: '/manage/couponissuerule/getlist', method: 'get' })
  },
  add: {
    p: 'post,/manage/couponissuerule/add',
    r: (data) => http({ url: '/manage/couponissuerule/add', method: 'post', data })
  },
  getOne: {
    p: 'post,/manage/couponissuerule/getone',
    r: (data) => http({ url: '/manage/couponissuerule/getone', method: 'post', data })
  },
  update: {
    p: 'post,/manage/couponissuerule/update',
    r: (data) => http({ url: '/manage/couponissuerule/update', method: 'post', data })
  },
  del: {
    p: 'post,/manage/couponissuerule/delete',
    r: (data) => http({ url: '/manage/couponissuerule/delete', method: 'post', data })
  }
};
