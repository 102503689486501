import http from '@/utils/http';

/**
 * 优惠卷管理
 */
export default {
  getList: {
    p: 'post,/manage/coupon/getlist',
    r: (data) => http({ url: '/manage/coupon/getlist', method: 'post', data })
  },
  add: {
    p: 'post,/manage/coupon/add',
    r: (data) => http({ url: '/manage/coupon/add', method: 'post', data })
  },
  update: {
    p: 'post,/manage/coupon/update',
    r: (data) => http({ url: '/manage/coupon/update', method: 'post', data })
  },
  del: {
    p: 'post,/manage/coupon/delete',
    r: (data) => http({ url: '/manage/coupon/delete', method: 'post', data })
  },
  getone: {
    p: 'post,/manage/coupon/getone',
    r: (data) => http({ url: '/manage/coupon/getone', method: 'post', data })
  },
  general: {
    p: 'post,/general/get_product',
    r: (data) => http({ url: '/general/get_product', method: 'post', data })
  },
  couponhistory: {
    p: 'post,/manage/couponhistory/getlist',
    r: (data) => http({ url: '/manage/couponhistory/getlist', method: 'post', data })
  },
  issue: {
    p: 'post,/manage/coupon/issue',
    r: (data) => http({ url: '/manage/coupon/issue', method: 'post', data })
  },
  issueGetOne: {
    p: 'post,/manage/couponissuerule/getone',
    r: (data) => http({ url: '/manage/couponissuerule/getone', method: 'post', data })
  }
};
