import './list.scss';
import dataApi from '@/api/product/product.js';

export default {
  name: 'product-list',
  components: {},
  data() {
    return {
      SubmitId: '',
      screenDa: {},
      drawerCourseVisible: false,
      selectProductRow: {
        id: 0
      },
      isForm: 'sku',
      dataApi,
      page: {
        pageSize: 20,
        pageSizes: [20, 30, 40, 50, 100]
      },
      planPage: {
        pageSize: 20,
        pageSizes: [20, 30, 40, 50, 100]
      },
      signPage: {
        pageSize: 20,
        pageSizes: [20, 30, 40, 50, 100]
      },
      dialogVisible: false,
      skuDialogVisible: false,
      signDialogVisible: false,
      dialogTitle: '设置规格库存',
      tableData: [],
      tbleOption: {
        total: 20,
        align: 'center',
        cellBtn: false,
        cancelBtn: false,
        addBtn: false,
        ...this.$store.getters.tableConfig,
        editBtn: false,
        selection: false,
        delBtn: false,
        index: false,
        filterBtn: false,
        column: [
          {
            label: '编号',
            prop: 'id',
            type: 'input'
          },
          {
            label: '活动图片',
            prop: 'pic',
            type: 'upload',
            listType: 'picture-img'
          },
          {
            label: '活动名称',
            prop: 'name',
            type: 'input',
            search: true
          },
          {
            type: 'select',
            label: '所属分类',
            display: true,
            props: {
              label: 'label',
              value: 'value'
            },
            prop: 'product_category_id',
            dicFlag: true,
            multiple: true,
            search: true,
            dicUrl: `${this.$baseUrl}general/get_product_category`,
            dicQuery: {
              scenario: 'product'
            },
            dicMethod: 'post',
            dataType: 'number'
          },
          {
            label: '价格',
            prop: 'price'
          },
          {
            label: '销量',
            prop: 'sale'
          },
          {
            label: 'sku',
            prop: 'sku',
            slot: true
          },
          {
            label: '排序',
            prop: 'sorting'
          },
          {
            type: 'radio',
            label: '状态',
            value: 1,
            dicData: [
              {
                label: '禁用',
                value: -1
              },
              {
                label: '启用',
                value: 1
              }
            ],
            display: true,
            search: 'true',
            prop: 'status'
          },
          {
            // 更新者 update_by
            label: '更新者',
            addDisplay: false,
            editDisplay: false,
            prop: 'update_user'
          },
          {
            // 更新时间 update_time
            label: '更新时间',
            addDisplay: false,
            editDisplay: false,
            prop: 'update_time'
          }
        ]
      },
      searchData: {},
      // 辅助搜索
      screenOp: {
        column: [
          {
            type: 'input',
            prop: 'id',
            display: false
          }
        ],
        disabled: false,
        clearExclude: ['status'],
        labelPosition: 'right',
        labelSuffix: ':',
        labelWidth: 110,
        gutter: 0,
        menuBtn: true,
        submitBtn: true,
        submitText: '提交',
        emptyBtn: false,
        emptyText: '清空',
        menuPosition: 'center'
      },
      formSkuDa: {},
      formSkuOp: [
        {
          label: '',
          prop: 'skuStockList',
          formslot: true,
          span: 24,
          display: true
        }
      ],
      // 班级
      tablePromOp: {
        total: 20,
        align: 'center',
        cellBtn: false,
        cancelBtn: false,
        addBtn: false,
        ...this.$store.getters.tableConfig,
        editBtn: false,
        selection: false,
        delBtn: false,
        index: false,
        filterBtn: false,
        searchBtn: false,
        column: [
          {
            label: '编号',
            prop: 'id',
            type: 'input'
          },
          {
            label: '所属规格',
            prop: 'product_attr_name'
          },
          {
            label: '活动名称',
            prop: 'name'
          },
          {
            label: '班级名称',
            prop: 'grade'
          },
          {
            label: '活动日期',
            prop: 'date_text',
            type: 'input'
          },
          {
            label: '报名人数',
            prop: 'apply_quantity',
            slot: true
          },
          {
            type: 'radio',
            label: '状态',
            value: 1,
            dicData: [
              {
                label: '禁用',
                value: -1
              },
              {
                label: '启用',
                value: 1
              }
            ],
            display: true,
            prop: 'status'
          }
        ]
      },
      tablePromDa: [],
      formOp: {
        column: [],
        labelPosition: 'left',
        labelSuffix: '：',
        labelWidth: 120,
        gutter: 0,
        menuBtn: true,
        submitBtn: true,
        submitText: '提交',
        emptyBtn: true,
        emptyText: '清空',
        menuPosition: 'center'
      },
      formDa: {},
      productAttrValue: [],
      reload: Math.random(),
      // 加载报名人名单
      tableSignOp: {
        total: 20,
        align: 'center',
        cellBtn: false,
        cancelBtn: false,
        addBtn: false,
        ...this.$store.getters.tableConfig,
        editBtn: false,
        selection: false,
        delBtn: false,
        index: false,
        filterBtn: false,
        searchBtn: false,
        menu: false,
        column: [
          {
            label: '编号',
            prop: 'member_id'
          },
          {
            label: '会员名称',
            prop: 'member_name'
          }
        ]
      },
      tableSignDa: []
    };
  },
  watch: {},
  computed: {
    formClassOp() {
      return [
        {
          type: 'input',
          display: false,
          prop: 'product_id'
        },
        {
          type: 'select',
          label: '所属规格',
          dicUrl: `${this.$baseUrl}general/get_product_attr_name`,
          dicQuery: {},
          dicMethod: 'post',
          span: 12,
          display: true,
          props: {
            label: 'label',
            value: 'value'
          },
          prop: 'sku_stock_id',
          required: true,
          rules: [
            {
              required: true,
              message: '请选择所属规格'
            }
          ]
        },
        {
          type: 'input',
          label: '活动名称',
          span: 12,
          display: true,
          prop: 'name',
          required: true,
          rules: [
            {
              required: true,
              message: '班级名称必须填写'
            }
          ]
        },
        {
          type: 'input',
          label: '班级名称',
          span: 12,
          display: true,
          prop: 'grade',
          required: true,
          rules: [
            {
              required: true,
              message: '班级名称必须填写'
            }
          ]
        },
        {
          type: 'daterange',
          label: '活动日期',
          span: 12,
          display: true,
          format: 'yyyy-MM-dd',
          valueFormat: 'yyyy-MM-dd',
          prop: 'date_range',
          required: true,
          pickerOptions: {
            disabledDate(time) {
              return time.getTime() < Date.now();
            }
          },
          rules: [
            {
              required: true,
              message: '活动日期必须填写'
            }
          ]
        },
        {
          type: 'timerange',
          label: '活动时间',
          span: 12,
          display: true,
          format: 'HH:mm',
          valueFormat: 'HH:mm',
          prop: 'class_time',
          required: true,
          rules: [
            {
              required: true,
              message: '活动时间必须填写'
            }
          ]
        },
        {
          type: 'checkbox',
          label: '活动日期',
          dicData: [
            {
              label: '周一',
              value: '1'
            },
            {
              label: '周二',
              value: '2'
            },
            {
              label: '周三',
              value: '3'
            },
            {
              label: '周四',
              value: '4'
            },
            {
              label: '周五',
              value: '5'
            },
            {
              label: '周六',
              value: '6'
            },
            {
              label: '周日',
              value: '0'
            }
          ],
          span: 24,
          display: true,
          props: {
            label: 'label',
            value: 'value'
          },
          prop: 'week',
          required: true,
          rules: [
            {
              required: true,
              message: '活动日期必须填写'
            }
          ]
        },
        {
          type: 'number',
          label: '人数',
          span: 12,
          display: true,
          prop: 'quantity',
          value: 1,
          required: true,
          rules: [
            {
              required: true,
              message: '人数必须填写'
            }
          ]
        },
        {
          type: 'select',
          label: '策划师',
          multiple: true,
          dicUrl: `${this.$baseUrl}general/get_teacher`,
          dicQuery: {},
          dicMethod: 'post',
          span: 12,
          display: true,
          props: {
            label: 'label',
            value: 'value'
          },
          prop: 'teacher_id',
          required: true,
          rules: [
            {
              required: true,
              message: '请选择签到的策划师'
            }
          ]
        },
        {
          type: 'input',
          span: 24,
          display: true,
          formslot: true,
          prop: 'button'
        },
        {
          type: 'dynamic',
          label: '活动安排',
          span: 24,
          display: true,
          children: {
            align: 'center',
            headerAlign: 'center',
            index: false,
            addBtn: false,
            delBtn: true,
            column: [
              {
                type: 'input',
                label: '活动计划',
                span: 24,
                display: true,
                prop: 'class',
                width: 200,
                prepend: '第',
                append: '节',
                required: true,
                rules: [
                  {
                    required: true,
                    message: '活动计划必须填写'
                  }
                ]
              },
              {
                type: 'input',
                label: '活动日期',
                span: 24,
                display: true,
                prop: 'class_time',
                required: true,
                disabled: true,
                rules: [
                  {
                    required: true,
                    message: '上课日期必须填写'
                  }
                ]
              },
              {
                type: 'input',
                label: '活动主题',
                span: 24,
                display: true,
                prop: 'title',
                required: true,
                rules: [
                  {
                    required: true,
                    message: '活动主题必须填写'
                  }
                ]
              },
              {
                type: 'select',
                label: '策划师',
                multiple: true,
                dicUrl: `${this.$baseUrl}general/get_teacher`,
                dicQuery: {},
                dicMethod: 'post',
                span: 12,
                display: true,
                props: {
                  label: 'label',
                  value: 'value'
                },
                prop: 'teacher_id',
                required: true,
                rules: [
                  {
                    required: true,
                    message: '上课老师必须填写'
                  }
                ]
              }
            ]
          },
          prop: 'course_content',
          required: true,
          rules: [
            {
              required: true,
              message: '活动安排必须填写'
            }
          ]
        },
        {
          type: 'radio',
          label: '状态',
          dicData: [
            {
              label: '启用',
              value: 1
            },
            {
              label: '禁用',
              value: -1
            }
          ],
          span: 24,
          display: true,
          props: {
            label: 'label',
            value: 'value'
          },
          prop: 'status',
          required: true,
          rules: [
            {
              required: true,
              message: '状态必须填写'
            }
          ],
          value: 1
        }
      ];
    }
  },
  mounted() {},
  filters: {},
  methods: {
    clickLink(e) {
      console.log('clickLink', e);
      this.$router.push(`/member/product?id=${e.id}`);
      // this.$router.push({
      //   path: `/member/product?id=${e.id}`
      // });
    },
    // 分页
    onLoad(page) {
      this.page = page;
      this.getList();
    },
    onPlanLoad(page) {
      this.page = page;
      this.getPlanList();
    },
    // 筛选
    searchChange(params, done) {
      this.searchData = params;
      this.page.currentPage = 1;
      this.getList();
      done();
    },
    // 筛选清空
    searchReset() {
      this.page.currentPage = 1;
      this.searchData = {};
      this.getList();
    },
    rowView(row) {
      this.getInfo(row);
      this.dialogVisible = true;
      this.formOp.disabled = true;
      this.formOp.submitBtn = false;
      this.formOp.emptyBtn = false;
    },
    // 添加
    addDialogForm(row) {
      this.formDa = {
        id: ''
      };
      if (row.id !== undefined) {
        this.getInfo(row);
      }
      this.dialogVisible = true;
      this.formOp.disabled = false;
      this.formOp.submitBtn = true;
      this.formOp.emptyBtn = true;
    },
    drawerClose() {
      this.dialogVisible = false;
      this.bankList = [];
      this.$refs.form.resetForm();
    },
    // 删除
    rowDel(form) {
      this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        console.log('删除数据', form);
        dataApi.del.r({ id: form.id }).then((res) => {
          console.log(res);
          this.getList();
          this.$message.success('删除成功');
        });
      });
    },
    getInfo(row) {
      dataApi.getOne.r({ id: row.id }).then((res) => {
        this.formDa = res.data;
        this.screenOpData = res.data;
      });
    },
    getList() {
      const postData = {
        page: this.page.currentPage,
        pageSize: this.page.pageSize,
        ...this.searchData
      };
      dataApi.getList.r(postData).then((res) => {
        this.tableData = res.data.list;
        this.page.total = res.data.total;
      });
    },
    plan(row) {
      this.drawerCourseVisible = true;
      this.selectProductRow = row;
    },
    getPlanList() {
      const postData = {
        page: this.planPage.currentPage,
        pageSize: this.planPage.pageSize,
        product_id: this.selectProductRow.id
      };
      dataApi.getCourseList.r(postData).then((res) => {
        this.tablePromDa = res.data.list;
        this.planPage.total = res.data.total;
      });
    },
    rowPlanDel(form) {
      this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        dataApi.delCourse.r({ id: form.id }).then(() => {
          this.getPlanList();
          this.$message.success('删除成功');
        });
      });
    },
    editArranging(row) {
      this.formClassOp[1].dicQuery = {
        product_id: this.selectProductRow.id
      };
      this.formOp.column = this.formClassOp;
      this.dialogTitle = '活动课时安排';
      this.isForm = 'course';
      this.formOp.labelWidth = 120;
      if (row.id > 0) {
        // 获取数据
        dataApi.getCourseInfo.r({ id: row.id }).then((res) => {
          if (res.data) {
            this.formDa = res.data;
          } else {
            this.formDa = {};
          }
        });
      } else {
        this.formDa = {
          product_id: this.selectProductRow.id,
          status: 1
        };
      }
      this.skuDialogVisible = true;
      this.reload = Math.random();
    },
    editSku(row) {
      const { id, product_attr_id, is_course } = row;
      this.formOp.column = this.formSkuOp;
      this.formOp.labelWidth = 0;
      const that = this;
      that.getProductAttrValue(0, product_attr_id);
      dataApi.getSkuInfo.r({ product_id: id }).then((res) => {
        that.formDa.skuStockList = res.data;

        // 打开sku 编辑框
        that.skuDialogVisible = true;
      });

      this.dialogTitle = '设置规格库存';
      this.isForm = 'sku';
      this.formDa.is_course = is_course;
      this.formDa.product_id = id;
      console.log(row);
      this.reload = Math.random();
    },
    // 获取活动属性
    getProductAttrValue(type, attrId) {
      dataApi.getProductAttrValue
        .r({ type: type, attr_id: attrId })
        .then((res) => {
          const list = res.data;
          if (type === 0) {
            this.productAttrValue = [];
            for (let i = 0; i < list.length; i++) {
              let options = [];
              let values = [];
              if (this.isEdit) {
                if (list[i].hand_add_status === 1) {
                  // 编辑状态下获取手动添加编辑属性
                  options = this.getEditAttrOptions(list[i].id);
                }
                // 编辑状态下获取选中属性
                values = this.getEditAttrValues(i);
              }
              this.productAttrValue.push({
                id: list[i].id,
                name: list[i].name,
                handAddStatus: list[i].hand_add_status,
                inputList: list[i].input_list,
                values: values,
                options: options
              });
            }
            if (this.isEdit) {
              // 编辑模式下刷新活动属性图片
              this.refreshProductAttrPics();
            }
          } else {
            this.productParamValue = [];
            for (let i = 0; i < list.length; i++) {
              let value = null;
              if (this.isEdit) {
                // 编辑模式下获取参数属性
                value = this.getEditParamValue(list[i].id);
              }
              this.productParamValue.push({
                id: list[i].id,
                name: list[i].name,
                value: value,
                inputType: list[i].inputType,
                inputList: list[i].inputList
              });
            }
          }
        });
    },
    handleRefreshCourse() {
      // 刷新活动
      // 判断活动日期范围、活动时间范围、活动日期是否为空
      if (this.formDa.date_range.length === 0) {
        this.$message.error('请选择活动日期范围');
        return false;
      }
      // if (!this.formDa.time_range) {
      //   this.$message.error('请选择上课时间');
      //   return false;
      // }
      if (this.formDa.week.length === 0) {
        this.$message.error('请选择活动日期');
        return false;
      }

      // 开始生成排课数据
      dataApi.getCourse.r(this.formDa).then((res) => {
        this.formDa.course_content = res.data;
      });
    },
    dialogSubmit(form, done) {
      if (this.isForm === 'sku') {
        dataApi.updateSku.r(form).then(() => {
          this.skuDialogVisible = false;
          done(form);
          this.getList();
        });
      }
      if (this.isForm === 'course') {
        dataApi.addCourse.r(form).then(() => {
          this.skuDialogVisible = false;
          done(form);
          this.getPlanList();
        });
      }
      done();
    },
    dialogCloseCallback() {
      this.skuDialogVisible = false;
      // 清空formDa数据
      this.$refs.skuForm.resetForm();
    },
    getProductSkuSp(row, index) {
      const spData = row.sp_data;
      if (spData != null && index < spData.length) {
        return spData[index].value;
      }
      return null;
    },
    getSignUpList(row) {
      this.signDialogVisible = true;
      const postData = {
        page: this.signPage.currentPage,
        pageSize: this.signPage.pageSize,
        product_id: row.product_id,
        product_course_id: row.id
      };
      dataApi.getSignUpList.r(postData).then((res) => {
        this.tableSignDa = res.data.list;
        this.signPage.total = res.data.total;
      });
    },
    dialogClose() {
      this.signDialogVisible = false;
    }
  }
};
