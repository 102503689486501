<template>
  <div :class="{ 'page-class': !$store.getters.tableConfig.card }">
    <avue-crud
      :data="tableData"
      :option="tableOption"
      @refresh-change="getList"
      @on-load="onLoad"
      @search-change="searchChange"
      @search-reset="searchReset"
      :page.sync="page"
    >
      <template slot="menuLeft">
      <el-button
          icon="el-icon-plus"
          type="primary"
          size="small"
          @click.stop="addCoupon()"
      >新增</el-button
      >
    </template>
      <template slot-scope="{ type, size, row, index }" slot="menu">
        <el-button
            icon="el-icon-view"
            :size="size"
            :type="type"
            @click.stop="rowView(row, index)"
        >查看</el-button>
        <el-button
            icon="el-icon-s-ticket"
            :size="size"
            :type="type"
            @click.stop="issue(row)"
        >优惠券发放</el-button>
        <el-button
            icon="el-icon-edit"
            :size="size"
            :type="type"
            @click.stop="rowEdit(row)"
        >编辑</el-button>
        <el-button
            icon="el-icon-delete"
            :size="size"
            :type="type"
            @click.stop="rowDel(row)"
        >删除</el-button>
      </template>
    </avue-crud>
    <el-drawer
        :title="title"
        size="70%"
        :close-on-click-modal="false"
        :visible.sync="dialogVisible"
    >
      <avue-form
          ref="form"
          v-model="formDa"
          :option="tableOption"
          @submit="rowUpdate"
          @reset-change="emptytChange"
      >
        <template slot="product">
          <div>
            <el-select  placeholder="请选择所属活动" multiple clearable v-model="formDa.product">
              <el-option v-for="item in dic" :key="item.value" :value="item.value" :label="item.label"></el-option>
            </el-select>
          </div>
        </template>
      </avue-form>
    </el-drawer>
    <el-drawer
        title="优惠详情"
        size="70%"
        :close-on-click-modal="false"
        :visible.sync="viewVisible"
    >
      <div class="Viewtable">
        <el-descriptions style="margin-bottom: 20px" direction="vertical" :column="6" border>
          <el-descriptions-item label="名称" :span="1">{{ formDa.name }}</el-descriptions-item>
          <el-descriptions-item label="优惠券类型" :span="1">{{ formDa.type_text }}</el-descriptions-item>
          <el-descriptions-item label="商品场景" :span="1">{{ formDa.scenario_text }}</el-descriptions-item>
          <el-descriptions-item label="使用类型" :span="1">{{ formDa.use_type_text }}</el-descriptions-item>
          <el-descriptions-item label="面值" :span="1">{{ formDa.amount }}</el-descriptions-item>
          <el-descriptions-item label="状态" :span="1">{{ formDa.status_text }}</el-descriptions-item>
          <el-descriptions-item label="有效期" :span="1">{{ formDa.end_time }}</el-descriptions-item>
          <el-descriptions-item label="使用门槛" :span="1">{{ formDa.min_point }}</el-descriptions-item>
          <el-descriptions-item label="数量" :span="1">{{ formDa.count }}</el-descriptions-item>
          <el-descriptions-item label="发行数量" :span="1">{{ formDa.publish_count }}</el-descriptions-item>
          <el-descriptions-item label="已使用" :span="1">{{ formDa.publish_count }}</el-descriptions-item>
          <el-descriptions-item label="未使用" :span="1">{{ formDa.receive_count }}</el-descriptions-item>
        </el-descriptions>
        <avue-crud
            :data="detailData"
            :option="detailOption"
            @refresh-change="detailGetList"
            @on-load="detailGetList"
            @search-change="searchChange"
            @search-reset="searchReset"
            :page.sync="page"
        >
        </avue-crud>
      </div>
    </el-drawer>
    <el-dialog
        title="优惠券发放"
        size="30%"
        :close-on-click-modal="false"
        :visible.sync="issueVisible"
    >
      <avue-form
          ref="form"
          v-model="issueDa"
          :option="issueOption"
          @submit="rowIssue"
          @reset-change="issueChange"
      >
      </avue-form>
    </el-dialog>
  </div>
</template>
<script>
import List from './list';

export default List;
</script>
