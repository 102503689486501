<template>
  <div :class="{ 'page-class': !$store.getters.tableConfig.card }">
    <avue-crud
      :data="tableData"
      :option="tbleOption"
      @row-save="handleSubmit"
      @row-update="rowUpdate"
      @refresh-change="getList"
      @row-del="rowDel"
      @on-load="onLoad"
      @search-change="searchChange"
      @search-reset="searchReset"
      :page.sync="page"
    >
      <template slot="menuLeft">
        <el-button
            icon="el-icon-plus"
            type="primary"
            size="small"
            @click.stop="add()"
        >新增</el-button
        >
      </template>
      <template slot-scope="{ type, size, row, index }" slot="menu">
        <el-button
            icon="el-icon-edit"
            :size="size"
            :type="type"
            @click.stop="rowEdit(row, index)"
        >编辑</el-button>
        <el-button
            icon="el-icon-delete"
            :size="size"
            :type="type"
            @click.stop="rowDel(row, index)"
        >删除</el-button>
      </template>
    </avue-crud>

    <el-dialog
        :title="title"
        size="30%"
        :close-on-click-modal="false"
        :visible.sync="dialogVisible"
    >
      <avue-form
          ref="form"
          v-model="formDa"
          :option="tbleOption"
          @submit="handleSubmit"
          @reset-change="emptytChange"
      >
        <template slot="product">
          <div>
            <el-select  placeholder="请选择所属活动" clearable v-model="formDa.product">
              <el-option v-for="item in dic" :key="item.value" :value="item.id" :label="item.title"></el-option>
            </el-select>
          </div>
        </template>
      </avue-form>
    </el-dialog>
  </div>
</template>
<script>
import List from './list';

export default List;
</script>
